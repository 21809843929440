import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import SecondaryLayout from "../components/layouts/SecondaryLayout"
import ServicePage from "../containers/ServicePage"

import useMessages from "../hooks/useMessages"

const SecondPage = () => {
  const [messages, format] = useMessages(["services"])

  const data = useStaticQuery(graphql`
    query {
      blocs: allMarkdownRemark(
        sort: { fields: [frontmatter___order], order: ASC }
        filter: {
          fileAbsolutePath: { regex: "/(CoursParticuliers)/" }
          frontmatter: { language: { eq: "fr" } }
        }
      ) {
        edges {
          node {
            id
            frontmatter {
              order
              col
              title
              content
            }
          }
        }
      }
      imageSection1: file(
        relativePath: {
          eq: "placeholders/front-view-of-adorable-caucasian-girl-study-at-D6SJYL3.jpg"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageSection2: file(
        relativePath: { eq: "page-blocus-assiste-section-2.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <SecondaryLayout>
      <ServicePage
        service="cours-particuliers"
        messages={messages}
        InfosNodes={data.blocs.edges}
        format={format}
        images={[
          data.imageSection1.childImageSharp.fluid,
          data.imageSection2.childImageSharp.fluid,
        ]}
      />
    </SecondaryLayout>
  )
}

export default SecondPage
